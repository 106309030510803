Utils = {
    removeQueryStringParameter: function (key, url) {
        if (!url) url = window.location.href;

        let hashParts = url.split('#');

        let regex = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");

        if (hashParts[0].match(regex)) {
            //REMOVE KEY AND VALUE
            url = hashParts[0].replace(regex, '$1');

            //REMOVE TRAILING ? OR &
            url = url.replace(/([?&])$/, '');

            //ADD HASH
            if (typeof hashParts[1] !== 'undefined' && hashParts[1] !== null)
                url += '#' + hashParts[1];
        }

        return url;
    },

    cookies: function(name, value, days) {
        let argslen = arguments.length;
        if (argslen == 0) return null;
        if (argslen == 1) {
            let result = (new RegExp('(^|;)\\s*'+name+'=(.*?)(;|$)')).exec(document.cookie);
            return result ? result[2] : null;
        }
        if (argslen == 2 && value === null) {
            value = '';
            days = -1;
        }

        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = '; expires='+date.toGMTString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
        return null;
    }
};

sendGaEvent = function(category, action, label, value) {
    var dlParams = {
        'event': 'event-to-ga',
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label || 'EMPTY',
        'eventValue': value || 1
    };

    if( typeof dataLayer != 'undefined' ) {
        dataLayer.push(dlParams);
    } else {
        console.log(dlParams);
    }
}

sendGTagEvent = function(category, action, data) {
    if( typeof gtag != 'undefined' ) {
        gtag(category, action, data);
    } else {
        console.log(category, action, data);
    }
}
