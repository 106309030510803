require('./app.js');
global.$ = global.jQuery = $;
require('icheck-2');
require('./sale_widget.js');
swal = require('./sweetalert2.all.min.js');
const Odometer = require('./odometer.js');

$(function() {
    let payBtn = $('#pay-button');
    let defaultPriceId = $('#checkout_priceId').val();
    let defaultAmount = $('#checkout_totalPrice').val();
    let defaultSupportOption = !!+$('#checkout_supportOption').val();


    payBtn.data('price-id', defaultPriceId);
    payBtn.data('amount', defaultAmount);
    payBtn.data('supportOption', defaultSupportOption);

    $('input').not( ".minimal-green-skin" ).icheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green'
    });

    //радиокнопки выбора тарифа
    $('input.minimal-green-skin').icheck({
        checkboxClass: 'icheckbox_minimal-green',
        radioClass: 'iradio_minimal-green'
    }).on('change',function() {
        $('#priceBase').toggleClass('additional_new');
        let curPriceId  = $(this).data('priceId');
        let currPrices = tariffs[curPriceId];

        if(currPrices !== undefined) {
            $('#oldPriceBase').html(currPrices['oldPrice'] + ' руб.');
            $('#priceBase').html(currPrices['price'] + ' руб.');
            $('#oldOptionPriceText').html(currPrices['oldPriceSupportOption'] + ' руб.');
            $('#optionPriceText').html(currPrices['priceSupportOption'] + ' руб.');

            payBtn.data('price-id', curPriceId);

            updatePrice(currPrices);
        }

        if($('#add_opt_checkbox').icheck('data').checked){
            payBtn.data('support-option', true);
        } else {
            payBtn.data('support-option', false);
        }
    });

    //обработчики выбора опции поддержки
    $('#add_opt_checkbox').on('ifUnchecked', function (event) {
        let curPriceId = payBtn.data('price-id');
        let currPrices = tariffs[curPriceId];
        updatePrice(currPrices);
        payBtn.data('support-option', false);
    });

    $('#add_opt_checkbox').on('ifChecked', function (event) {
        let curPriceId = payBtn.data('price-id');
        let currPrices = tariffs[curPriceId];
        updatePrice(currPrices);
        payBtn.data('support-option', true);
    });

    //Виджет выбора платежного метода
    $('.modal__purchase-method').on('click', function (e) {

        $(this).find('[type=radio]').trigger('click');

        if ($(this).hasClass('accordion') || $(this).hasClass('modal__purchase-method--visa_master-card')) {

            if ($(this).hasClass('modal__purchase-method--visa_master-card')){
                $('input[type=radio][name=category]').icheck('unchecked').icheck('enabled');
                payBtn.data('pay-method', $(this).data('method'));
            }

            $(this).data('current', true);

            $('.accordion.modal__purchase-method').each(function () {
                if($(this).hasClass('modal__purchase-method--active') && !$(this).data('current')){
                    var method = $(this).data('method');
                    var class_t = '.modal__purchase-'+method;
                    $(class_t).toggle(class_t+'--visible');
                    $(this).toggleClass('modal__purchase-method--active');
                    $(this).find('i').toggleClass('up').toggleClass('down');
                    $(this).icheck('enabled');
                }
            });

            var method = $(this).data('method');
            var class_t = '.modal__purchase-'+method;
            $(class_t).toggle(class_t+'--visible');
            $(this).toggleClass('modal__purchase-method--active');
            $(this).find('i').toggleClass('up').toggleClass('down');

            $(this).data('current', false);

            return;
        }
        payBtn.data('pay-method', $(this).data('method'));
    });

    $('input[type=radio]').not( ".minimal-green-skin" ).on('click', function(event){
        event.stopPropagation();
        if($(this).attr('name') == 'category'){
            if ($(this).prop('checked')){
                $('input[type=radio][name=method]').icheck('unchecked');
                $('#pay-button').addClass('disable');
            }
            $('input[type=radio][name=category]').icheck('unchecked');
            $(this).icheck('checked');
            $(this).icheck('disabled');
        } else {
            $('#pay-button').removeClass('disable');
        }
    });

    updatePrice = function(currPrices){
        let oldOptionPrice = 0;
        let OptionPriceValue = 0;
        let oldPriceValue = currPrices['oldPrice'];
        let priceValue = currPrices['price'];

        if($('#add_opt_checkbox').icheck('data').checked){
            oldOptionPrice = currPrices['oldPriceSupportOption'];
            OptionPriceValue = currPrices['priceSupportOption'];
        }

        let savePrice = (oldPriceValue - priceValue) + (oldOptionPrice - OptionPriceValue);

        $('#totalPrice').html(priceValue + OptionPriceValue + ' руб.');
        $('#savePrice').html(savePrice + ' руб.');

        payBtn.data('amount', priceValue + OptionPriceValue);
    };

    let validateEmail = function validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };


    if ($('#email').val().length == 0) {
        $('#email').focus();
    } else {
        if (!validateEmail($('#email').val().trim())) {
            $('#email').addClass('c-error').removeClass('c-valid');
        } else {
            $('#email').addClass('c-valid').removeClass('c-error');
        }
    }
    $('#email').on('keyup', function() {
        if(!$(this).hasClass('c-changed')){
            return true;
        }
        if (!validateEmail($(this).val().trim())) {
            $(this).addClass('c-error').removeClass('c-valid');
        } else {
            $(this).addClass('c-valid').removeClass('c-error');
        }
    });

    payBtn.on('click', function(e){
        e.preventDefault();

        if($(this).hasClass('disable')){
            return false;
        }

        let $data = $(this).data();
        let $email = $('#email');

        if ($email.val().length !== 0) {
            $email.addClass('c-changed');
        }

        $data['email'] = $email.val().trim();

        if (!validateEmail($data['email'])) {
            $email.addClass('c-error').removeClass('c-valid');
            return false;
        } else {
            $email.addClass('c-valid').removeClass('c-error');
        }
        sendGTagEvent('event', 'purchase', {
            'currency': 'RUB',
            'value': $data['amount'],
            'shipping': $data['payMethod'],
            'coupon':null});
        $amount = parseFloat($data['amount']);
        $yandexKassa.init($data['payMethod']);
        $yandexKassa.pay($data);

    });

    $yandexKassa = {
        payMethods: [
            "bank_card","yandex_money","qiwi","webmoney","sberbank","alfabank",
            "tinkoff_bank","mobile_balance","cash"
        ],
        payMethod : "bank_card",
        init: function (payMethod) {
            var index = this.payMethods.indexOf(payMethod);
            if(index === -1){
                console.warn("method " + payMethod + " not found, use default method bank_card");
            }else {
                this.payMethod = this.payMethods[index];
            }
        },

        pay: function ($data) {
            switch(this.payMethod) {
                case "bank_card" :
                    this.yandexKassaHandler($data);
                    break;
                case "yandex_money" :
                    this.yandexKassaHandler($data);
                    break;
                default:
                    this.yandexKassaHandler($data);
                    break;
            }
        },

        yandexKassaHandler: function ($data) {
            $.ajax({
                "method": "POST",
                "url": $data['action'],
                "data": $data
            }).done(function( data ) {
                if(undefined !== data.status && data.status === 'fail') {
                    showAlert(data.message);
                }
                if(undefined !== data.confirmation && undefined !== data.confirmation.confirmation_url) {
                    document.location.href = data.confirmation.confirmation_url;
                }
            });
        },
    };

    let $errorBlock = $('.error');

    let showAlert = function(message){
        $('<div>', {id: 'errorData'}).appendTo('body');
        $("#errorData").html(message);
        showMessage();
    };

    $('.error-close').click(function () {
        $errorBlock.addClass('close');
        $('.error-open').removeClass('error-open');
        $('.backdrop').removeClass('backdrop')
    });

    if($errorBlock.data('message') !== undefined) {
        showAlert($errorBlock.data('message'));
    }

    function showMessage() {
        swal({
            title: $('#errorData').html(),
            type:"error",
            confirmButtonText:"OK",
            confirmButtonColor:"#2a445d",
            allowOutsideClick: false,
            width: 500
        })
    }

    if ($('#errorData').length) {
        showMessage();
    }

    //odometer init

    function i(){
        var t=Date.now()-14832288e5;
        return Math.floor(5e5*Math.log(t/4242e5))
    }

    function r(){
        var t=Date.now()-14932288e5;
        return Math.floor(2e6*Math.log(t/1264e5))
    }

    od1=new Odometer({
        el:document.querySelector(".counter-protected"),
        value:i(),
        format:"(,ddd)",
        duration:1e4
    });

    od2=new Odometer({
        el:document.querySelector(".counter-malware"),
        value:r(),
        format:"(,ddd)",
        duration:1e4
    });

    setTimeout(function(){
        setInterval(function(){
            od1.update(i());
        },5e3)
        setInterval(function(){
            od2.update(r());
        }, 1e2)
    },6e3)


});
