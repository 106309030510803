$(function() {
    Utils = {
        removeQueryStringParameter: function (key, url) {
            if (!url) url = window.location.href;

            let hashParts = url.split('#');

            let regex = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");

            if (hashParts[0].match(regex)) {
                //REMOVE KEY AND VALUE
                url = hashParts[0].replace(regex, '$1');

                //REMOVE TRAILING ? OR &
                url = url.replace(/([?&])$/, '');

                //ADD HASH
                if (typeof hashParts[1] !== 'undefined' && hashParts[1] !== null)
                    url += '#' + hashParts[1];
            }

            return url;
        },
        cookies: function(name, value, days) {
            let argslen = arguments.length;
            if (argslen == 0) return null;
            if (argslen == 1) {
                let result = (new RegExp('(^|;)\\s*'+name+'=(.*?)(;|$)')).exec(document.cookie);
                return result ? result[2] : null;
            }
            if (argslen == 2 && value === null) {
                value = '';
                days = -1;
            }

            let expires = '';
            if (days) {
                let date = new Date();
                date.setTime(date.getTime()+(days*24*60*60*1000));
                //date.setTime(date.getTime()+(60*1000));
                expires = '; expires='+date.toGMTString();
            }
            document.cookie = name + '=' + value + expires + '; path=/';
            return null;
        },

        getParameterByName: function(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }
    };
    //Utils.cookies('sale50-telamon', -1);
    var settings = {
        shown: false,
        appearHeight: 21,
        cookieName: 'sale50-' + $('#j-exip-p').data('offer'),
        deathTime: 16*60-1,
        priceId: $('#priceId').val() * 1,

        $body: $('#j-exip-p'),
        $btn: $('#j-exip-p .exip-popup-btn'),
        $close: $('#j-exip-p .j-closer'),

        $tmin: $('#j-exip-p .exip-p-timer-el .minutes'),
        $tsec: $('#j-exip-p .exip-p-timer-el .seconds'),
    };
    var prevY = -100;
    var exitpopup = {
        init: function() {
            exitpopup.attachEvents();
        },
        attachEvents: function() {
            setTimeout(function() { exitpopup.show(); }, 15000);
            $(window).on('mousemove.exitpopup', function(e){
                if (!settings.shown && e.pageY>0 && e.clientY < settings.appearHeight && prevY - e.clientY > 0) {
                    exitpopup.show();
                    $(window).off('mousemove.exitpopup');
                } else {
                    prevY = e.clientY;
                }
            });

            settings.$close.on('click.exitpopup', function() {
                settings.$body.hide();
                exitpopup.timerstop();
                //sendGaEvent('DiscountOffer', 'close', '', '');
                return false;
            });

            settings.$btn.on('click.exitpopup',function(){
                //sendGaEvent('DiscountOffer', 'apply', '', '');
            });
        },

        show: function() {
            // if (settings.priceId != 77) {
            //     return;
            // }
            return;
            if(Utils.getParameterByName('t') !== null){
                return;
            }
            //return;
            var cookie = Utils.cookies(settings.cookieName);
            var left = settings.deathTime;
            if (cookie && cookie != "-1") {
                left = cookie - exitpopup.time();
                return;
            } else {
                Utils.cookies(settings.cookieName, exitpopup.time() + settings.deathTime, 1);
            }

            if (left < 0) return;
            exitpopup.timeriter(left);

            settings.shown = true;
            settings.$body.show();

            //sendGaEvent('DiscountOffer', 'popup_show', '', '');
        },

        time: function() {
            return Math.floor((new Date()).getTime()/1000);
        },
        timershow: function(time) {
            var s = time % 60;
            var m = Math.floor(time/60);
            m = (m > 9 ? m : '0'+m);
            s = (s > 9 ? s : '0'+s);
            settings.$tmin.text(m);
            settings.$tsec.text(s);

            if(time == 0) {
                settings.$body.hide();
            }
        },
        timerstop: function () {
            settings.timeout && clearTimeout(settings.timeout);
        },
        timeriter: function (left) {
            if (left>0) {
                exitpopup.timershow(left);
                settings.timeout = setTimeout(function(){
                    exitpopup.timeriter(left-1);
                }, 1000);
            } else {
                exitpopup.timershow(0);
                settings.$btn.addClass('disabled').attr('href', '#');
                settings.$btn.on('click', function(){
                    return false;
                });
            }
        }
    }

    exitpopup.init();

});
